<template>
  <div
    class="surface-card p-4 shadow-2 border-round w-full lg:w-6"
    style="margin: auto"
  >
    <div class="text-center mb-5">
      <img
        :src="publicPath + 'images/logo-white.svg'"
        alt="Image"
        height="100"
      />
      <div class="text-900 text-3xl font-medium mb-3">Recupera tu cuenta</div>
      <!--
      <span class="text-600 font-medium line-height-3"
        >¿No tienes una cuenta?</span
      >
      <router-link
        class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
        to="/signup"
        >¡Créala hoy!</router-link
      >-->
    </div>

    <div v-if="!submitted">
      <label for="email1" class="block text-900 font-medium mb-2">Email</label>
      <InputText
        id="email1"
        v-model="email"
        v-on:blur="checkEmail"
        type="text"
        class="w-full mb-3"
      />
      <div class="flex align-items-center justify-content-between mb-6">
        <span class="p-invalid" v-if="errors.email">{{ errors.email }}</span>
      </div>

      <Button
        label="Recuperar la cuenta"
        :loading="loading"
        icon="pi pi-user"
        @click="sendCode()"
        class="w-full"
      ></Button>
    </div>
    <div v-else>
      <p class="text-center">Se ha mandado un email a {{ email }}.</p>
      <p class="text-center">
        Sigue las intrucciones del email para recuperar tu cuenta.
      </p>
    </div>
  </div>
</template>

<script>
import MerchantService from '../../service/MerchantService';
import { validateEmail } from '../../utils/validation';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      email: '',
      merchantService: null,
      errors: {},
      loading: false,
      submitted: false,
    };
  },
  created() {
    this.merchantService = new MerchantService();
  },
  methods: {
    async sendCode() {
      this.checkEmail();
      if (this.errors.email) return;
      this.loading = true;
      await this.merchantService.sendRecoveryEmail(this.email);
      this.loading = false;
      this.submitted = true;
    },
    checkEmail() {
      this.errors.email = validateEmail(this.email)
        ? null
        : 'El email tiene un formato no válido';
    },
  },
};
</script>
